function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 86400));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
function eraseCookie(name) {   
    document.cookie = name+'=; Max-Age=date.getTime() - 86400;';  
}

$(document).ready(function(){

    /*if(!('IntersectionObserver' in window)){*/

    /* unsupported, just load everything */
    /*
    $("[data-src]").each(function(){
        $(this).attr('srcset', $(this).data("srcset"));
        $(this).removeAttr("data-srcset");
        $(this).attr('src', $(this).data("src"));
        $(this).removeAttr("data-src");
    });
    */

    /*} else {*/

    function preloadImage(img){
        var src    = img.getAttribute('data-src');
        var srcset = img.getAttribute('data-srcset');
        if(!src){
            return;
        }

        img.srcset = srcset;
        img.src    = src;
        img.onload = function(){
            img.removeAttribute('data-src');
            img.removeAttribute('data-srcset');
        };

    }
    
    var observer = new IntersectionObserver(function(entries, self){
        entries.forEach(function(entry){
            if(entry.isIntersecting){
                preloadImage(entry.target);
                self.unobserve(entry.target);
            }
        });
    }, {
        rootMargin: '0px 0px 50px 0px',
        threshold: 0
        });

    $("img[data-src]").each(function(){
        observer.observe(this);
    });

    /*}*/

});

$(document).ready(function(){

    /*
     *  REQUIRED for responsive menu
     *  add button to open submenus
     *  ****************************
     */
    $('#mobileNavigation .mobileMainMenu li').each(function(){
        if($(this).children('ul').length > 0){
            $(this).children(":first").prepend('<span class="openSubMenu"></span>');
            $(this).addClass('hasChild');
        }
    });
    
    $(document).on('click', '.mobileMainMenu .openSubMenu', function(e){

        e.preventDefault();

        var parentLi = $(this).closest("li");
        var auntLi = parentLi.siblings("li");

        // close submenus
        auntLi.find("ul").slideUp();
        parentLi.find("ul").slideUp();
        
        if(!parentLi.find("ul:first").is(":visible")){
            auntLi.find(".subMenuOpen").removeClass('subMenuOpen');
            $(this).addClass('subMenuOpen');
            parentLi.find("ul:first").slideDown();
        } else {
            parentLi.find(".subMenuOpen").removeClass('subMenuOpen');
        }
        
    });
    
});

$(document).ready(function(){

    function fixIeObjectFit(){

        $('.objectFitImage').each(function(){
            var img    = $(this).find('img');
            var imgURL = img.attr('src');
            if(!imgURL){
                imgURL = img.attr('data-src');
            }
            img.hide();
            $(this).css('background-image', 'url(' + imgURL + ')');
            $(this).css('background-size', 'cover');
            $(this).css('background-position', 'center center');
        });

    }

    // Detect objectFit support
    if(document.createElement("detect").style.objectFit === ""){
        return;

    } else {
        fixIeObjectFit();

    }

});

