$(document).ready(function(){

    $("a[rel^='prettyPhoto']").prettyPhoto({
                                               overlay_gallery: false,
                                               social_tools: false,
                                               slideshow: false,
                                               allow_resize: true,
                                               deeplinking: false,
                                           });

    $("a[rel^='external']").on('click', function(e){
        e.preventDefault();
        window.open(this.href);
    });

    /*
    * disable links
    */
    $(".disabled").on('click', function(e){
        e.preventDefault();
    });

    /*
    * show/hide header search
    */
    $('#showHeaderSearch').on('click', function(e){
        e.preventDefault();
        $('#headerSearchForm').slideToggle();
        $(this).toggleClass('headerSearchFormActive');
    });

    /*
    * market area rep map
    */

    $('.repStateDropdown').on('change', function(){
        var repState       = $(this).val();
        var detailsWrapper = $('#details');
        detailsWrapper.fadeOut('fast', function(){
            detailsWrapper.html('');
            if($('.' + repState).length > 0){
                $('.' + repState).each(function(){
                    detailsWrapper.append($(this).html());
                });
            } else {
                detailsWrapper.append($('.default_rep').html());
            }
            detailsWrapper.fadeIn('fast');
        });
    });

});

$(document).ready(function(){

    $.validator.addMethod('customphone', function(value, element){
        return this.optional(element) || /^\d{3}-\d{3}-\d{4}$/.test(value);
    }, " Please use the format: 555-555-1212");
    
    $("#register_member_form").validate({
                                            rules: {
                                                username: {
                                                    required: true,
                                                    minlength: 4
                                                },
                                                password: {
                                                    required: true,
                                                    minlength: 5
                                                },
                                                password_confirm: {
                                                    required: true,
                                                    minlength: 5,
                                                    equalTo: "input[name='password']"
                                                },
                                                email: {
                                                    required: true,
                                                    email: true
                                                },
                                                email_confirm: {
                                                    required: true,
                                                    email: true,
                                                    equalTo: "input[name='email']"
                                                },
                                                m_field_id_1: {
                                                    required: true
                                                },
                                                m_field_id_2: {
                                                    required: true
                                                },
                                                m_field_id_3: {
                                                    required: true,
                                                    minlength: 3
                                                },
                                                m_field_id_6: {
                                                    required: true,
                                                    customphone: true,
                                                    minlength: 11
                                                },
                                                m_field_id_7: {
                                                    required: true
                                                },
                                                m_field_id_8: {
                                                    required: true
                                                },
                                                m_field_id_9: {
                                                    required: true
                                                },
                                                m_field_id_10: {
                                                    required: true,
                                                    minlength: 5
                                                },
                                                captcha: {
                                                    required: true
                                                }
                                            },
                                            messages: {
                                                username: {
                                                    required: " Please enter a username",
                                                    minlength: " Your username must consist of at least 4 characters"
                                                },
                                                password: {
                                                    required: " Please provide a password",
                                                    minlength: " Your password must be at least 5 characters long"
                                                },
                                                password_confirm: {
                                                    required: " Please provide a password",
                                                    minlength: " Your password must be at least 5 characters long",
                                                    equalTo: " Please enter the same password as above"
                                                },
                                                email: " Please enter a valid email address",
                                                email_confirm: {
                                                    required: " Please enter a valid email address",
                                                    equalTo: " Please enter the same email address as above"
                                                },
                                                m_field_id_1: " Please enter your first name.",
                                                m_field_id_2: " Please enter your last name.",
                                                m_field_id_3: {
                                                    required: " Please enter your company name.",
                                                    minlength: " Must be at least 3 characters long."
                                                },
                                                m_field_id_6: {
                                                    required: " Please enter your phone number.",
                                                    minlength: " Must be at least 7 characters long."
                                                },
                                                m_field_id_7: " Please enter your address.",
                                                m_field_id_8: " Please enter your city.",
                                                m_field_id_9: " Please enter your state.",
                                                m_field_id_10: {
                                                    required: " Please enter your zip.",
                                                    minlength: " Must be at least 5 characters long."
                                                },
                                                captcha: " Please enter the security code.",
                                            }
                                        });

});
